import React, { useEffect, useState } from 'react';
import { Card, Col, Input, Row, Typography, Spin } from 'antd';
import { baseurl } from '../../components/lib/settings';
import { format } from 'date-fns';
import './AdminAmountReportsByDelegationCompany.css';
import { LoadingOutlined } from '@ant-design/icons';

const { Meta } = Card;
const { Search } = Input;
const { Title } = Typography;

function AdminAmountReportsByDelegationCompany() {
  const [groupedData, setGroupedData] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const resp = await baseurl.post("v3/ngoma/administrator/financing/by_delegations/");
      const data = resp.data;
      const groupedByYear = data.reduce((acc, item) => {
        const year = new Date(item.first_vote_date).getFullYear();
        if (!acc[year]) acc[year] = [];
        acc[year].push(item);
        return acc;
      }, {});
      setGroupedData(groupedByYear);
      setFilteredData(groupedByYear);
    } catch (e) {
      console.error('Failed to fetch data:', e);
    } finally {
      setIsLoading(false); // Set loading to false once data is fetched
    }
  };

  const formatToMetical = (amount) => {
    return `${amount.toLocaleString('pt-BR', { style: 'currency', currency: 'MZN' })}`;
  };

  const formatStartDate = (formatDate) => {
    return format(new Date(formatDate), "dd/MM/yyyy");
  };

  const formatEndDate = (formatDate) => {
    return format(new Date(formatDate), "dd/MM/yyyy");
  };

  const handleSearch = (value) => {
    setSearchTerm(value.toLowerCase());
    const filtered = {};
    Object.keys(groupedData).forEach(year => {
      filtered[year] = (groupedData[year] || []).filter(item =>
        item.delegation_description && item.delegation_description.toLowerCase().includes(value.toLowerCase())
      );
    });
    setFilteredData(filtered);
  };

  return (
    <div className='query-query-container'>

      <br />
      <Title
        level={2}
        style={{
          textAlign: 'center',
        }}
      >
        Total acumulado por delegação
      </Title>
      <Search
        placeholder="Pesquisar por descrição da delegação"
        onChange={(e) => handleSearch(e.target.value)}
        style={{ marginBottom: 16 }}
      />
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  }}>
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 40,
                }}
                spin
              />
            }
          />
        </div>
      ) : (
        <>
          {Object.keys(filteredData).sort((a, b) => b - a).map(year => (

            <div key={year} className="year-section">
              <h2>{year}</h2>
              <Card style={{ backgroundColor: "#eeeeee", marginBottom: "20px" }}>
                <Row gutter={[24, 24]}>
                  {filteredData[year].map((item, index) => (
                    <Col key={index} xs={24} sm={12} md={8} lg={6}>
                      <Card
                        hoverable
                        title={item.delegation_description || "Descrição não disponível"}
                        style={{ width: "100%" }}
                        cover={
                          <div className="date-cover">
                            De {formatStartDate(item.first_vote_date)} a {formatEndDate(item.last_vote_date)}
                          </div>
                        }
                      >
                        <Meta className='moneyCss'
                          description={`Total: ${formatToMetical(item.delegation_total_amount || 0)}`}
                        />
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Card>
            </div>
          ))}

        </>
      )}
    </div>
  );
}

export default AdminAmountReportsByDelegationCompany;
