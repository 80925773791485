import React, { useState } from "react";
import { Form, Input, Button, Card, Row, Col, Typography, InputNumber, Alert } from "antd";
import { baseurl } from "../../../components/lib/settings";
import "./createm.css"

const { Title } = Typography;

const CreateMusician = () => {
  const [musician, setMusician] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [erroMessage, setErrorMessage] = useState(false)
  const [alertType, setAlertType] = useState('')
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true)
    const data = {
      song_number: values.song_number,
      author: values.author,
      title: values.title,
      contact: values.contact,
      wpp_contact: values.wpp_contact,
      voting_code: values.voting_code,
      request_source: "web_platform"
    }
    baseurl.post('v1/manage_musician/', data).then((resp) => {
      setMusician(resp.data)
      if (resp.status === 200 || resp.status === 201) {
        form.resetFields();
        setAlertType("success")
        setErrorMessage(resp.data)
      }
      setIsLoading(false)

    }).catch((error) => {
      setIsLoading(false)
      setAlertType("warning")
      setErrorMessage(error.response.data[0])
    })
  };

  const inputStyle = {
    width: "100%",
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: "100vh", backgroundColor: "#eeeeee" }}>
      <Col span={20}>

        <Card>

          <Title level={2} style={{ textAlign: "center" }}>
            Registo de Músicos para Votação
          </Title>
          <br />

          <div className="messages-notif">
            <div>
              {erroMessage && (
                <><Alert style={{ width: "100%" }} message={erroMessage} type={alertType} showIcon /></>
              )}
            </div>
          </div>

          <br />

          <Form
            form={form}
            name="musician_registration"
            onFinish={onFinish}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
          >
            <Form.Item
              label="Número da canção"
              name="song_number"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira o número da canção!",
                },
              ]}
            >
              <InputNumber disabled={isLoading} block size="large" min={1} style={inputStyle} />
            </Form.Item>

            <Form.Item
              label="Autor"
              name="author"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira o nome do autor!",
                },
              ]}
            >
              <Input disabled={isLoading} size="large" />
            </Form.Item>

            <Form.Item
              label="Título"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira o título da música!",
                },
              ]}
            >
              <Input disabled={isLoading} size="large" />
            </Form.Item>

            <Form.Item
              label="Contacto"
              name="contact"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira o contacto!",
                },
              ]}
            >
              <InputNumber prefix="+258" disabled={isLoading} block size="large" min={100000000} max={999999999} style={inputStyle} />
            </Form.Item>

            <Form.Item
              label="Número do WhatsApp"
              name="wpp_contact"
              rules={[
                {
                  required: true,
                  message: "Por favor, insira o número do WhatsApp!",
                },
              ]}
            >
              <InputNumber prefix="+258" disabled={isLoading} block size="large" min={100000000} max={999999999} style={inputStyle} />
            </Form.Item>

            <Form.Item
              label="Código de Votação"
              name="voting_code"

              rules={[
                {
                  required: true,
                  message: "Por favor, insira o código de votação!",
                },
              ]}
            >
              <Input disabled={isLoading} size="large" placeholder="Introduza o código que será usado para votar o músico" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
              <Button block type="primary" htmlType="submit" loading={isLoading}>
                Registrar novo músico
              </Button>
            </Form.Item>
          </Form>
          <br />
        </Card>
      </Col>
    </Row>
  );
};

export default CreateMusician;