import React, { useState } from 'react';
import { Button, Card, Col, Form, Input, Row, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import NgomaLogo from "../../assets/logos/rm-logo.png";
import { baseurl } from '../../components/lib/settings';
import { useNavigate } from 'react-router-dom';
import './login.css'; // Adicione o arquivo CSS
const { Title } = Typography;

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  // const onFinish = (values) => {
  //   setIsLoading(true);
  //   setMessage("")
  //   const data = {
  //     user_name: values.email,
  //     password: values.password,
  //   };
  //   localStorage.removeItem("token");

  //   baseurl.post("auth/login/", data).then((resp) => {
  //     if (resp.data.token) {
  //       localStorage.setItem("token", resp.data.token);
  //       getUserData(resp.data.token)
  //       baseurl
  //         .get("auth/user/", { params: { user: resp.data.token, }, })
  //         .then((resp) => {
  //           localStorage.setItem("user", JSON.stringify(resp.data.user));
  //         });
  //       navigate("/voting/all");
  //       setIsLoading(false);
  //     } else {
  //       setMessage("Senha ou e-mail inválidos.");
  //       setIsLoading(false);
  //       setError(true);
  //     }
  //   })
  //     .catch((err) => {
  //       setMessage("Senha ou email inválidos.")
  //       setError(true);
  //       setIsLoading(false);
  //     });
  // };

  const onFinish = (values) => {
    setIsLoading(true);
    setMessage("");
    const data = {
      user_name: values.email,
      password: values.password,
    };
    localStorage.removeItem("token");

    baseurl.post("auth/login/", data)
      .then((resp) => {
        if (resp.data.token) {
          localStorage.setItem("token", resp.data.token);

          baseurl.get("auth/user/", {
            params: { token: resp.data.token }
          })
            .then((userResp) => {
              localStorage.setItem("user", JSON.stringify(userResp.data.user));
              navigate("/dashboard");
            })
            .catch((err) => {
              console.error("Failed to fetch user data:", err);
              setMessage("Erro ao carregar dados do usuário.");
            });

          setIsLoading(false);
        } else {
          setMessage("Senha ou e-mail inválidos.");
          setIsLoading(false);
          setError(true);
        }
      })
      .catch((err) => {
        setMessage("Senha ou email inválidos.");
        setError(true);
        setIsLoading(false);
      });
  };


  const getUserData = (token) => {
    baseurl.get("auth/user/", token).then((response) => {
      if (response.data.token) {
        // Handle response
      }
    })
  };

  return (
    <Row className='login-page'>
      <Col xs={24} md={12} className='logo-section'>
        <img src={NgomaLogo} alt='Ngoma-Logo' className='logo-image' />
      </Col>
      <Col xs={24} md={12} className='form-section'>
        <Card hoverable className='login-card'>

          <Title level={2}>Entrar</Title>
          <p style={{ color: "red" }}> {message}</p>

          <Form name='login' onFinish={onFinish}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Por favor, insira o seu e-mail!' },
              ]}
            >
              <Input
                disabled={isLoading}
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder='E-mail'
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Por favor, insira a sua senha!" },
              ]}
            >
              <Input.Password
                disabled={isLoading}
                prefix={<LockOutlined className='site-form-icon' />}
                placeholder='Senha'
              />
            </Form.Item>
            <div className='links'>
              <a href="#" disabled>Esqueci a senha</a>
              <a href="#" disabled>Registrar</a>
            </div>
            <Form.Item>
              <Button
                type='primary'
                htmlType='submit'
                className='login-form-button'
                block
                loading={isLoading}
              >
                Iniciar sessão
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default Login;
