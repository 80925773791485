import React, { useState, useEffect } from 'react';
import { Table, Skeleton, Card, Divider, Typography, Spin, Button, Input } from 'antd';
import { baseurl } from '../../../components/lib/settings';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DownloadOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';

const { Title } = Typography;

const columns = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Autor',
    dataIndex: 'author',
    key: 'author',
  },
  {
    title: 'Título',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Código de Votação',
    dataIndex: 'voting_code',
    key: 'voting_code',
  },
  {
    title: 'Contacto',
    dataIndex: 'contact',
    key: 'contact',
  },
  {
    title: 'Número de Whatsapp',
    dataIndex: 'whatsapp_number',
    key: 'whatsapp_number',
  },
];

const MusicianList = () => {
  const [musicianList, setMusicianList] = useState([]);
  const [filteredMusicianList, setFilteredMusicianList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState({ description: '' });
  const [searchText, setSearchText] = useState('');
  const current_date = new Date().toLocaleDateString();

  useEffect(() => {
    setIsLoading(true);
    baseurl.post('v2/ngoma/musicians_by_company/')
      .then((resp) => {
        setMusicianList(resp.data);
        setFilteredMusicianList(resp.data);
        // setCompany(resp.data.company); // Uncomment if company data is available
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const filteredData = musicianList.filter((musician) =>
      Object.values(musician).some(value =>
        value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
    setFilteredMusicianList(filteredData);
  }, [searchText, musicianList]);

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = columns.map(col => col.title);
    const tableRows = filteredMusicianList.map((musician, index) => [
      index + 1,
      musician.author,
      musician.title,
      musician.voting_code,
      musician.contact,
      musician.whatsapp_number,
    ]);

    doc.text(`Data: ${current_date}`, 14, 16);
    doc.text(`Lista de Canções - ${company.description}`, 14, 22);
    doc.autoTable({
      startY: 30,
      head: [tableColumn],
      body: tableRows,
    });

    doc.save('musician_details.pdf');
  };

  return (
    <div style={{ padding: "20px" }}>
      <Divider orientation='left'>
        <Title level={2} style={{ textAlign: "center" }}>
          Lista de todos os músicos registados {(isLoading && <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 25,
                }}
                spin
              />
            }
          />) || ` ${company.description}`}
        </Title>
      </Divider>
      {isLoading ? (
        <Skeleton active style={{ width: '100%', textAlign: 'center' }} />
      ) : (
        <Card style={{ backgroundColor: "#eeeeee" }}>
          <Divider orientation='left'>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={exportToPDF}
              style={{ marginBottom: '10px' }}
            >
              Exportar para PDF
            </Button>
          </Divider>
          <Input
            placeholder="Buscar"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            prefix={<SearchOutlined />}
            style={{ marginBottom: '10px', width: '300px' }}
          />
          <Table
            columns={columns}
            dataSource={filteredMusicianList.map((musician, index) => ({ ...musician, key: index }))}
            pagination={{ pageSize: 100 }}
          />
        </Card>
      )}
    </div>
  );
};

export default MusicianList;
