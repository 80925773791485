import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/login/login";
import DashboardLayout from "./pages/dashboard/dashboardLayout";
import PrivateRoutes from "./routes_private";
import NotFound404 from "./pages/404/404";
import VotingList from "./pages/mainContent/voting/votingList";
import MusicianList from "./pages/mainContent/musician/musicianList";
import CreateMusician from "./pages/mainContent/musician/createMusician";
import VotingFilter from "./pages/mainContent/musician/votingFilter";
import Dashboard from "./pages/dashboard/dashboard";
import AdminAmountReportsByDelegationCompany from "./pages/reports/AdminAmountReportsByDelegationCompany";
import VotingQuery from "./pages/reports/VotingQuery";
import DailyVotingList from "./pages/reports/DailyVotingList";
import NewDashboardLayout from "./pages/dashboard/newDashboardLayout";

const AppRouter = () => {
  return (
    <Router>
      <div className="container">
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route
              path="/dashboard"
              element={
                <NewDashboardLayout>
                  <Dashboard />
                </NewDashboardLayout>
              }
            />
            <Route
              path="/voting/all"
              element={
                <NewDashboardLayout>  <VotingList />  </NewDashboardLayout>
              }
            />
            <Route
              path="/voting/category"
              element={
                <NewDashboardLayout>
                  <VotingFilter />
                </NewDashboardLayout>
              }
            />
            <Route
              element={
                <NewDashboardLayout>
                  <NotFound404 />
                </NewDashboardLayout>
              }
              path="*"
            />
            <Route
              path="/admin/musician/all"
              element={
                <NewDashboardLayout>
                  <MusicianList />
                </NewDashboardLayout>
              }
            />
            <Route
              path="/musician/all"
              element={
                <NewDashboardLayout>
                  <MusicianList />
                </NewDashboardLayout>
              }
            />

            {/* <Route
              path="/voting/daily/showall/"
              element={
                <NewDashboardLayout>
                  <DailyVotingList />
                </NewDashboardLayout>
              }
            /> */}

            <Route
              path="/admin/musician/create"
              element={
                <NewDashboardLayout>
                  <CreateMusician />
                </NewDashboardLayout>
              }
            />
            <Route
              path="/admin/finance/reports"
              element={
                <NewDashboardLayout>
                  <AdminAmountReportsByDelegationCompany />
                </NewDashboardLayout>
              }
            />
            <Route
              path="/admin/finance/query/votings"
              element={
                <NewDashboardLayout>
                  <VotingQuery />
                </NewDashboardLayout>
              }
            />
            {/* <Route
              path="/newdashboard"
              element={
                <NewDashboardLayout>
                  <VotingQuery />
                </NewDashboardLayout>
              }
            /> */}
          </Route>
          <Route path="/" element={<Login />} exact />
        </Routes>
      </div>
    </Router>
  );
};

export default AppRouter;